import { useState } from "react";
import {
  Button,
  Grid,
  DatePicker,
  Dialog,
  CardHeader,
  CardContent,
  CardActions,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "../../../components";
import { formatDate, responseErros } from "../../../utils";
import NfeService from "../../../services/api/nfe.service";
import fileDownload from "js-file-download";
import { NfSaidaService, notification } from "../../../services";
import JSZip from "jszip";

const DialogBaixarXml = ({
  openDialog,
  setOpenDialog,
  contadorDashboard,
  setContadorDashboard,
  empresa,
}) => {
  const [downloadRelatorioPdf, setDownloadRelatorioPdf] = useState(false);
  const nfeService = new NfeService();
  const nfSaidaService = new NfSaidaService();
  const zip = new JSZip();
  const listaStatus = [
    {
      id: 1,
      label: "Enviada",
      value: "ENVIADA",
      color: "#4FBB53",
    },
    {
      id: 2,
      label: "Cancelada",
      value: "CANCELADA",
      color: "#DC3545",
    },
  ];

  const onClickFechar = () => {
    setOpenDialog(false);
    setDownloadRelatorioPdf(false);
  };

  const onChangeSelect = (event) => {
    setContadorDashboard({
      ...contadorDashboard,
      [event.target.name]: event.target.value ?? null,
    });
  };

  const onChangeDate = (date, name) => {
    if (!date) {
      date = "Invalid Date";
    }
    setContadorDashboard({
      ...contadorDashboard,
      [name]: date != "Invalid Date" ? formatDate.toSend(date) : "",
    });
  };

  const valueInput = (valueName) => {
    return contadorDashboard[valueName] ? contadorDashboard[valueName] : "";
  };

  const onChangeCheckbox = (event) => {
    setDownloadRelatorioPdf(event.target.checked);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (!empresa.cnpjCpf) {
      notification.alertaGenericos("");
      onClickFechar();
    }
    const { ano, mes } = extrairDataReferencia(contadorDashboard.date);
    const titleFiles = `xml-${ano}-${mes}`;
    try {
      const xmlResponse = await nfeService.baixarXml(empresa.cnpjCpf, ano, mes);
      const xml = xmlResponse.data;
      zip.file(`${titleFiles}.zip`, xml, { base64: true });
      if (downloadRelatorioPdf) {
        const pdfResponse = await nfSaidaService.getRelatorioDocumentoContador(
          empresa.id,
          { empresa, dataEmissaoStart: contadorDashboard.date }
        );
        const pdfData = pdfResponse.data?.data;
        if (pdfData) {
          zip.file(`pdf-${titleFiles}.pdf`, pdfData, { base64: true });
        }
      }
      const content = await zip.generateAsync({ type: "blob" });
      const dados = downloadRelatorioPdf ? content : xml;
      fileDownload(dados, `${titleFiles}.zip`);
    } catch (error) {
      if (error.isAxiosError && error.response?.status === 400) {
        notification.alertaGenericos(
          `Diretório do mês ${mes} não foi encontrado.`
        );
      } else {
        responseErros(error);
      }
    } finally {
      onClickFechar();
    }
  };

  function extrairDataReferencia(date) {
    return {
      ano: new Date(date).getFullYear(),
      mes: new Date(date).getMonth() + 1,
    };
  }

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="md"
      open={openDialog}
      setOpenDialog={setOpenDialog}
      onClose={onClickFechar}
    >
      <CardHeader title="Baixar XML e Relatorios" />
      <form onSubmit={(event) => onSubmit(event)}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DatePicker
                required
                shrink
                label="Mês/Ano"
                placeholder="Selecione Mês e Ano"
                format="MM/yyyy"
                views={["month", "year"]}
                value={contadorDashboard?.date}
                onChange={(date) => onChangeDate(date, "date")}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                label="Status"
                id="status"
                name="status"
                variant="outlined"
                value={valueInput("status")}
                onChange={onChangeSelect}
              >
                {listaStatus.map((status) => (
                  <MenuItem key={status.id} value={status.value}>
                    <i
                      className="ph-fill ph-circle"
                      style={{ color: `${status.color}`, marginRight: 8 }}
                    ></i>{" "}
                    {status.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={downloadRelatorioPdf}
                    value={downloadRelatorioPdf}
                    onChange={(event) => onChangeCheckbox(event)}
                    name="downloadRelatorioPdf"
                    id="downloadRelatorioPdf"
                    color="primary"
                  />
                }
                label="Gerar Relatórios em PDF"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button type="submit" color="primary" variant="contained">
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Baixar
          </Button>
          <Button onClick={onClickFechar} color="padrao" variant="contained">
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Cancelar
          </Button>
        </CardActions>
      </form>
    </Dialog>
  );
};

export default DialogBaixarXml;
