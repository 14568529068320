/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Collapse,
  Drawer,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Topbar,
} from "../../components";
import "./main-layout.scss";
import { useImportContext } from "../../contexts/import.context";
import { SessionService, history, notification } from "../../services";
import shortid from "shortid";

const MainLayout = ({ children, match }) => {
  const id = match.params?.id;
  const location = useLocation();
  const {
    openCollapse,
    setOpenCollapse,
    openCadastros,
    setOpenCadastros,
    openCompras,
    setOpenCompras,
    openVendas,
    setOpenVendas,
    openFinanceiro,
    setOpenFinanceiro,
    openRelatorios,
    setOpenRelatorios,
    openContador,
    setOpenContador,
    initialCollapses,
  } = useImportContext();
  const [open, setOpen] = useState(openCollapse);
  const [openCollapseCadastros, setOpenCollapseCadastros] =
    useState(openCadastros);
  const [openCollapseCompras, setOpenCollapseCompras] = useState(openCompras);
  const [openCollapseVendas, setOpenCollapseVendas] = useState(openVendas);
  const [openCollapseRelatorios, setOpenCollapseRelatorios] =
    useState(openRelatorios);
  const [openCollapseContador, setOpenCollapseContador] =
    useState(openContador);
  const [openCollapseFinanceiro, setOpenCollapseFinanceiro] =
    useState(openFinanceiro);
  const [currentPathname, setCurrentPathname] = useState("");
  // const permisoesHelper = new PermisoesHelper();
  const sessionService = new SessionService();

  const handleLogout = async () => {
    notification
      .confirmacao("Tem certeza que deseja sair?")
      .then(async (result) => {
        if (result.value === true) {
          setOpenCollapse(false);
          initialCollapses();
          sessionService.deslogar();
        }
      });
  };

  const verificarCaminho = () => {
    setCurrentPathname(location?.pathname);
  };

  const handleOpenCollapse = (openCollapse, setOpenCollapse) => {
    if (open === false) {
      setOpen(true);
      setOpenCollapse(true);
    }
    setOpenCollapse(!openCollapse);
  };

  const handleDrawerOpen = () => {
    setOpen((state) => {
      setOpenCollapseCadastros((x) => {
        return state === false ? x : false;
      });
      setOpenCollapseCompras((x) => {
        return state === false ? x : false;
      });
      setOpenCollapseVendas((x) => {
        return state === false ? x : false;
      });
      /* setOpenCollapseServicos((x) => {
        return state === false ? x : false;
      }); */
      /* setOpenCollapseEstoque((x) => {
        return state === false ? x : false;
      }); */
      setOpenCollapseFinanceiro((x) => {
        return state === false ? x : false;
      });
      /* setOpenCollapseFiscal((x) => {
        return state === false ? x : false;
      }); */
      setOpenCollapseRelatorios((x) => {
        return state === false ? x : false;
      });
      if (state) initialCollapses();
      setOpenCollapse(!state);
      return !state;
    });
  };

  useEffect(() => {
    verificarCaminho();
  }, []);

  const subMenuCadastros = [
    {
      section: {
        title: "Geral",
        permissions: ["pessoas", "grupo-pessoas"],
      },
      title: "Pessoas",
      path: "/integracao/pessoas",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-users",
      permission: "pessoas",
    },
    {
      section: {
        permissions: ["pessoas", "grupo-pessoas"],
      },
      title: "Grupo de Pessoas",
      path: "/integracao/grupo-pessoas",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-users-three",
      permission: "grupo-pessoas",
    },
    {
      section: {
        title: "Produto",
        permissions: [
          "produtos",
          "grupos-produtos",
          "modelos-grade",
          "tabelas-preco",
          "regras-desconto",
        ],
      },
      title: "Produtos",
      path: "/estoque/produtos",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-package",
      permission: "produtos",
    },
    {
      section: {
        permissions: [
          "produtos",
          "grupos-produtos",
          "modelos-grade",
          "tabelas-preco",
          "regras-desconto",
          "saldo-inicial",
        ],
      },
      title: "Grupos de Produtos",
      path: "/estoque/grupo-produtos",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-rows",
      permission: "grupo-produtos",
    },
    {
      section: {
        title: null,
        permissions: [],
      },
      title: "Saldo Inicial",
      path: "/estoque/saldo-inicial",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-cube-focus",
      permission: "saldo-inicial",
    },
    /* {
      section: {
        permissions: [
          "produtos",
          "grupos-produtos",
          "modelos-grade",
          "tabelas-preco",
          "regras-desconto",
        ],
      },
      title: "Modelos de Grade",
      path: "/estoque/modelos-grade",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-squares-four",
      permission: "modelos-grade",
    },
    {
      section: {
        permissions: [
          "produtos",
          "grupos-produtos",
          "modelos-grade",
          "tabelas-preco",
          "regras-desconto",
        ],
      },
      title: "Tabelas de Preço",
      path: "/estoque/tabelas-preco",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-table",
      permission: "tabelas-preco",
    },
    {
      section: {
        permissions: [
          "produtos",
          "grupos-produtos",
          "modelos-grade",
          "tabelas-preco",
          "regras-desconto",
        ],
      },
      title: "Regras de Desconto",
      path: "/estoque/regras-desconto",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-tag",
      permission: "regras-desconto",
    }, */
    {
      section: {
        title: "Faturamento",
        permissions: ["meios-pagamento", "condicoes-pagamento", "tabela-preco"],
      },
      title: "Meios de Pagamento",
      path: "/faturamento/meios-pagamento",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-credit-card",
      permission: "meios-pagamento",
    },
    {
      section: {
        permissions: ["meios-pagamento", "condicoes-pagamento", "tabela-preco"],
      },
      title: "Condições de Pagamento",
      path: "/faturamento/condicoes-pagamento",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-credit-card",
      permission: "condicoes-pagamento",
    },
    /* {
      section: {
        title: "Financeiro",
        permissions: [
          "centro-custo",
          "planos-financeiros",
          "classificacoes-financeiras",
          "bancos-caixas",
        ],
      },
      title: "Centros de Custo",
      path: "/financeiro/centro-custo",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-squares-four",
      permission: "centro-custo",
    },
    {
      section: {
        permissions: [
          "centro-custo",
          "planos-financeiros",
          "classificacoes-financeiras",
          "bancos-caixas",
        ],
      },
      title: "Planos Financeiros",
      path: "/financeiro/planos-financeiros",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-chart-pie-slice",
      permission: "planos-financeiros",
    },
    {
      section: {
        permissions: [
          "centro-custo",
          "planos-financeiros",
          "classificacoes-financeiras",
          "bancos-caixas",
        ],
      },
      title: "Classificações Financeiras",
      path: "/financeiro/classificacoes-financeiras",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-presentation-chart",
      permission: "classificacoes-financeiras",
    }, */
    {
      section: {
        title: "Financeiro",
        permissions: [
          "centro-custo",
          "planos-financeiros",
          "classificacoes-financeiras",
          "bancos-caixas",
        ],
      },
      title: "Bancos e Caixas",
      path: "/financeiro/bancos-caixas",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-bank",
      permission: "bancos-caixas",
    },
    {
      section: {
        title: "Fiscal",
        permissions: [
          "grupos-tributario",
          "operacoes-fiscais",
          "modelos-tributacao",
        ],
      },
      title: "Grupos Tributários",
      path: "/fiscal/grupos-tributario",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-chart-pie",
      permission: "grupos-tributario",
    },
    {
      section: {
        permissions: [
          "grupos-tributario",
          "operacoes-fiscais",
          "modelos-tributacao",
        ],
      },
      title: "Operações Fiscais",
      path: "/fiscal/operacoes-fiscais",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-calculator",
      permission: "operacoes-fiscais",
    },
    {
      section: {
        permissions: [
          "grupos-tributario",
          "operacoes-fiscais",
          "modelos-tributacao",
        ],
      },
      title: "Modelo Tributação",
      path: "/fiscal/modelos-tributacao",
      isOpen: openCollapseCadastros,
      icon: "ph-fill ph-chart-bar",
      permission: "modelos-tributacao",
    },
  ];

  const menu = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: "ph-fill ph-house",
      subMenu: [],
    },
    {
      title: "Cadastros",
      path: null,
      icon: "ph-fill ph-file-plus",
      handleColapse: () => {
        handleOpenCollapse(openCollapseCadastros, setOpenCollapseCadastros);
      },
      isOpen: openCollapseCadastros,
      setOpen: setOpenCollapseCadastros,
      setOpenCollapse: setOpenCadastros,
      subMenu: subMenuCadastros,
    },
    {
      title: "Compras",
      path: null,
      icon: "ph-fill ph-shopping-cart",
      handleColapse: () => {
        handleOpenCollapse(openCollapseCompras, setOpenCollapseCompras);
      },
      isOpen: openCollapseCompras,
      setOpen: setOpenCollapseCompras,
      setOpenCollapse: setOpenCompras,
      subMenu: [
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "NF Entrada",
          path: "/estoque/nf-entrada",
          isOpen: openCollapseCompras,
          icon: "ph-fill ph-receipt",
          permission: "nf-entrada",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Documento Frete",
          path: "/estoque/documentos-frete",
          isOpen: openCollapseCompras,
          icon: "ph-fill ph-truck",
          permission: "documentos-frete",
        },
      ],
    },
    {
      title: "Vendas",
      path: null,
      icon: "ph-fill ph-wallet",
      handleColapse: () => {
        handleOpenCollapse(openCollapseVendas, setOpenCollapseVendas);
      },
      isOpen: openCollapseVendas,
      setOpen: setOpenCollapseVendas,
      setOpenCollapse: setOpenVendas,
      subMenu: [
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Orçamentos",
          path: "/faturamento/orcamentos",
          isOpen: openCollapseVendas,
          icon: "ph-fill ph-receipt",
          permission: "orcamentos",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Pedidos",
          path: "/faturamento/pedidos",
          isOpen: openCollapseVendas,
          icon: "ph-fill ph-file-text",
          permission: "pedidos",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "NF Saída",
          path: "/faturamento/nf-saida",
          isOpen: openCollapseVendas,
          icon: "ph-fill ph-scroll",
          permission: "nf-saida",
        },
      ],
    },
    {
      title: "Financeiro",
      path: null,
      icon: "ph-fill ph-money",
      handleColapse: () => {
        handleOpenCollapse(openCollapseFinanceiro, setOpenCollapseFinanceiro);
      },
      isOpen: openCollapseFinanceiro,
      setOpen: setOpenCollapseFinanceiro,
      setOpenCollapse: setOpenFinanceiro,
      subMenu: [
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Contas a Pagar",
          path: "/financeiro/contas-pagar",
          isOpen: openCollapseFinanceiro,
          icon: "ph-bold ph-money",
          permission: "contas-pagar",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Contas a Receber",
          path: "/financeiro/contas-receber",
          isOpen: openCollapseFinanceiro,
          icon: "ph-fill ph-coins",
          permission: "contas-receber",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Caixa",
          path: "/financeiro/caixa",
          isOpen: openCollapseFinanceiro,
          icon: "ph-fill ph-desktop",
          permission: "caixa",
        },
      ],
    },
    /* {
      title: "Fiscal",
      path: null,
      icon: "ph-bold ph-scales",
      handleColapse: () => {
        handleOpenCollapse(openCollapseFiscal, setOpenCollapseFiscal);
      },
      isOpen: openCollapseFiscal,
      subMenu: [
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Monitor Fiscal",
          path: "/fiscal/monitor-fiscal",
          isOpen: openCollapseFiscal,
          icon: "ph-fill ph-monitor",
          permission: "monitor-fiscal",
        },
      ],
    }, */
    {
      title: "Relatórios",
      path: null,
      icon: "ph-bold ph-chart-line-up",
      handleColapse: () => {
        handleOpenCollapse(openCollapseRelatorios, setOpenCollapseRelatorios);
      },
      isOpen: openCollapseRelatorios,
      setOpen: setOpenCollapseRelatorios,
      setOpenCollapse: setOpenRelatorios,
      subMenu: [
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Compras",
          path: "/relatorios-compras",
          isOpen: openCollapseRelatorios,
          icon: "ph-bold ph-chart-line",
          permission: "relatorios",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Vendas",
          path: "/relatorios-vendas",
          isOpen: openCollapseRelatorios,
          icon: "ph-bold ph-chart-line-up",
          permission: "relatorios",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Estoque",
          path: "/relatorios-estoque",
          isOpen: openCollapseRelatorios,
          icon: "ph-bold ph-chart-line",
          permission: "relatorios",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Contas a Pagar",
          path: "/relatorios-contas-pagar",
          isOpen: openCollapseRelatorios,
          icon: "ph-bold ph-chart-line-up",
          permission: "relatorios",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Contas a Receber",
          path: "/relatorios-contas-receber",
          isOpen: openCollapseRelatorios,
          icon: "ph-bold ph-chart-line",
          permission: "relatorios",
        },
      ],
    },
    {
      title: "Contador",
      path: null,
      icon: "ph-fill ph-math-operations",
      handleColapse: () => {
        handleOpenCollapse(openCollapseContador, setOpenCollapseContador);
      },
      isOpen: openCollapseContador,
      setOpen: setOpenCollapseContador,
      setOpenCollapse: setOpenContador,
      subMenu: [
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "Contas a Receber",
          path: "/contador/contas-receber",
          isOpen: openCollapseContador,
          icon: "ph-bold ph-chart-line",
          permission: "relatorios",
        },
        {
          section: {
            title: null,
            permissions: [],
          },
          title: "NF Entrada",
          path: "/contador/nf-entrada",
          isOpen: openCollapseContador,
          icon: "ph-bold ph-chart-line",
          permission: "relatorios",
        },
      ],
    },
    /*{
      title: "Pdv",
      path: "/pdv",
      icon: "ph-fill ph-storefront",
      subMenu: [],
    },*/
  ];

  const verificarCaminhoMenu = (path) => {
    return (
      (currentPathname === path ||
        currentPathname === `${path}/create` ||
        currentPathname === `${path}/${id}`) &&
      path !== ""
    );
  };

  const openSubMenu = (subMenu, item) => {
    history.push(subMenu.path);
    initialContextMenu();
    if (openCollapse) item.setOpenCollapse(item.isOpen);
  };

  function getMenusComPermissao(menu) {
    return menu.filter((menuItem) => {
      if (menuItem.subMenu.length === 0) {
        return true;
      }
      /* return menuItem.subMenu.some((submenuItem) =>
        permisoesHelper.temPermisaoMenu(submenuItem.permission)
      ); */
    });
  }

  const initialContextMenu = () => {
    setOpenCadastros(false);
    setOpenCompras(false);
    setOpenVendas(false);
    setOpenFinanceiro(false);
    setOpenRelatorios(false);
  };

  return (
    <div className={clsx({ root: true })}>
      <Topbar handleDrawerOpen={handleDrawerOpen} open={open} />
      <Drawer
        color="secondary"
        className={clsx("drwPaper", !open && "drwPaperClose")}
        variant="permanent"
        classes={{
          paper: clsx("drawerPaper", !open && "drawerPaperClose"),
        }}
        open={open}
      >
        <Box className="d-flex flex-wrap justify-content-center align-items-center flex-column w-100 py-3 mainLogo">
          <Collapse in={open} orientation="horizontal" collapsedSize={43}>
            <img
              src="https://public.siaflite.com.br/logo-lite-horizontal.svg"
              className="mainLogo-icon"
            />
          </Collapse>
        </Box>
        <List className="px-3" style={{ marginBottom: 84 }}>
          {getMenusComPermissao(menu).map((item) =>
            item.subMenu.length === 0 ? (
              <Tooltip
                title={item.title}
                arrow
                placement="left"
                key={shortid.generate()}
              >
                <ListItemButton
                  className={`center itens ${
                    currentPathname === item.path
                      ? clsx(
                          "listItemActive-open",
                          !open && "listItemActive-close"
                        )
                      : "listItem"
                  } p-2 my-2`}
                  onClick={() => {
                    initialContextMenu();
                    history.push(item.path);
                  }}
                >
                  <ListItemIcon className="center">
                    <i
                      className={`${item.icon} ${
                        currentPathname === item.path
                          ? "listItemActive-icon"
                          : "listItem-icon"
                      }`}
                    ></i>
                  </ListItemIcon>
                  {open && (
                    <ListItemText
                      className={`listItem-text ${
                        currentPathname === item.path
                          ? "listItemActive-text"
                          : "listItem-text"
                      }`}
                      primary={item.title}
                      disableTypography
                    />
                  )}
                </ListItemButton>
              </Tooltip>
            ) : (
              <div key={shortid.generate()}>
                <Tooltip
                  title={item.title}
                  arrow
                  placement="left"
                  key={shortid.generate()}
                >
                  <ListItemButton
                    className={`center itens ${
                      item.subMenu.find((subMenuItem) =>
                        verificarCaminhoMenu(subMenuItem.path)
                      )
                        ? "listItemActive-close"
                        : "listItem"
                    } p-2 my-1`}
                    onClick={item.handleColapse}
                  >
                    <ListItemIcon className="center">
                      <i
                        className={`${item.icon} ${
                          currentPathname === item.path
                            ? "listItemActive-icon"
                            : "listItem-icon"
                        }`}
                        style={{
                          fontSize: 20,
                        }}
                      ></i>
                    </ListItemIcon>
                    {open && (
                      <>
                        <ListItemText
                          className={`listItem-text ${
                            currentPathname === item.path
                              ? "listItemActive-text"
                              : "listItem-text"
                          }`}
                          primary={item.title}
                          disableTypography
                        />
                        <ListItemIcon className="center">
                          <i
                            className="ph-bold ph-caret-down"
                            style={{
                              fontSize: 15.5,
                              rotate: item.isOpen ? "180deg" : "0deg",
                              transition: "all 0.3s ease-in-out",
                            }}
                          ></i>
                        </ListItemIcon>
                      </>
                    )}
                  </ListItemButton>
                </Tooltip>
                {item.subMenu.map((subMenuItem) => (
                  <Collapse
                    key={shortid.generate()}
                    inn={subMenuItem.isOpen}
                    timeout="auto"
                    unmountOnExit
                  >
                    <div className="my-2">
                      {/* {permisoesHelper.temPermisaoMenu(
                        subMenuItem.section.permissions
                      ) &&
                        subMenuItem.section.title && (
                          <ListItem className="my-2">
                            <Typography
                              style={{ paddingLeft: 20 }}
                              className="subtitle"
                              variant="overline"
                            >
                              {subMenuItem.section.title}
                            </Typography>
                          </ListItem>
                        )}
                      {permisoesHelper.temPermisaoMenu(
                        subMenuItem.permission
                      ) && (
                        <ListItemButton
                          className={`center itens listSubItem ${
                            verificarCaminhoMenu(subMenuItem.path)
                              ? "listItemActive-open"
                              : ""
                          }`}
                          onClick={() => openSubMenu(subMenuItem, item)}
                        >
                          <ListItemIcon className="center">
                            <i
                              className={`${subMenuItem.icon} ${
                                verificarCaminhoMenu(subMenuItem.path)
                                  ? "listItemActive-icon"
                                  : "listItem-icon"
                              }`}
                            ></i>
                          </ListItemIcon>
                          <ListItemText
                            className={`listSubItem-text`}
                            primary={subMenuItem.title}
                            disableTypography
                          />
                        </ListItemButton>
                      )} */}
                    </div>
                  </Collapse>
                ))}
              </div>
            )
          )}
        </List>
        <Box
          className={`${clsx(
            "drwPaper-open",
            !open && "drwPaperClose-exit"
          )} position-fixed bottom-0 p-3 bg-white`}
        >
          <ListItemButton
            className={`center itens listItem-exit p-2 my-1`}
            onClick={() => {
              handleLogout();
            }}
          >
            <ListItemIcon className="center">
              <i
                className={`ph-bold ph-sign-out listItem-icon`}
                style={{
                  fontSize: 20,
                  color: "white",
                }}
              ></i>
            </ListItemIcon>
            {open && (
              <>
                <ListItemText
                  className={`listItem-text`}
                  primary="Sair"
                  sx={{
                    color: "white",
                  }}
                  disableTypography
                />
              </>
            )}
          </ListItemButton>
        </Box>
      </Drawer>
      <Grid container>
        <Grid item xs={12}>
          <main className={clsx("content", open && "content-open")}>
            {children}
          </main>
        </Grid>
      </Grid>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withRouter(MainLayout);
